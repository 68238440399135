import React from 'react';
import ReactDOM from 'react-dom';
import { useParams, useLocation, Link } from "react-router-dom";

import { HeaderLoading, GoBack } from "./Parts/Header.js";
import { getTicketById, updateTicket, getAllProjects, saveCommentData, deleteTicket, updateComment, getFilesById, updatePlanPosition, getPlansApi, getProjectById, updateTodos } from '../helper/restapi.js';

import WebViewer from '@pdftron/webviewer';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} location={useLocation()} />;
}

class TicketDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			id: '',
			data: [],
			userId: '',
			userRole: '',
			userFirstname: '',
			location: '',
			isAssignee: false,
			isEditor: false,
			isOpenClose: false,
			projects: [],
			edit: false,
			users: [],
			unread: 0,
			readby: [],
			ticketData: {
				title: '',
				description: '',
				deadline: '',
				aid: '',
				deadline: '',
			},
			showComments: false,
			message: '',
			planPath: null,
			showPlan: false,
			plan: null,
			addPlan: false,
			// checklist: [
			// 	{
			// 		item: 'Punkt 1',
			// 		done: false,
			// 	},
			// 	{
			// 		item: 'Punkt 2',
			// 		done: true,
			// 	}
			// ],
			checklist: [],
		};
		this.viewer = React.createRef();
	}

	async getTicket(id, reinit) {
		const response = await getTicketById(id);
		
		if (response.data.data.state == 1) {
			if (response.data.data.aid == this.props.user.user_id) {
				updateTicket(id, response.data.data.title, response.data.data.description, response.data.data.aid, response.data.data.file, 2, response.data.data.deadline, null);
			}			
		}

		if (response.data.data.aid == this.props.user.user_id) {
			this.setState({ isAssignee: true })
			this.setState({ isOpenClose: true })
		}
		if (response.data.data.cid == this.props.user.user_id || this.props.user.user_role == 1) {
			this.setState({ isEditor: true })
			this.setState({ isOpenClose: true })
		}

		if (response.data.comments.length > 0) {
			var currentUser = this.props.user.user_id.toString();
			var comments = response.data.comments;
			var unRead = 0;
			var readByArr = [];
			Object.keys(comments).forEach((key) => {
				if (comments[key].readby) {
					var readBy = comments[key].readby;
					this.setState({readby: readBy});
					var readBy = JSON.parse(readBy);
					if (!readBy.includes(currentUser)) {
						readByArr.push(comments[key]);
						unRead++;
					}
				}
			});

			this.setState({ unread: unRead, readby: readByArr });
			
		}

		if (response.data.data.project) {
			const res = await getProjectById(response.data.data.project);
			if (res) {
				if (res.data.data.houses.length > 0) {
					this.setState({ houses: res.data.data.houses})
				}
			}			
		}

		if (response.data.data.todos) {
			if (response.data.data.todos.length > 0 && response.data.data.todos !== null) {
				var todos = JSON.parse(response.data.data.todos);
				this.setState({ checklist: todos })
			}			
		}

		
		this.setState({
			ticketData: {
				...this.state.ticketData,
				'id': response.data.data.id,
				'title': response.data.data.title,
				'description': response.data.data.description,
				'aid': response.data.data.aid,
				'file': response.data.data.file,
				'state': response.data.data.state,
				'deadline': response.data.data.deadline,
			}
		});

		if (response.data.data.plan && response.data.data.plancoords) {
			const res = await getFilesById(response.data.data.plan);
			this.setState({ planPath: res.data.data.fullpath});
			this.setState({ plan: response.data.data.plan})

			if (reinit) {
				this.setState({showPlan: true});
				this.initializeWebViewer(res.data.data.fullpath, response.data.data);
			}
			
		}

		this.setState({ data: response.data });
		this.setState({ isReady: true });
	}

	closeTicket = () => {
		const currentDate = new Date();

		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, '0');
		const day = String(currentDate.getDate()).padStart(2, '0');

		const hours = String(currentDate.getHours()).padStart(2, '0');
		const minutes = String(currentDate.getMinutes()).padStart(2, '0');
		const seconds = String(currentDate.getSeconds()).padStart(2, '0');

		const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

		const shouldClose = window.confirm('Möchten Sie das Ticket als abgeschlossen markieren?');
		if (shouldClose) {
			updateTicket(this.state.data.data.id, this.state.data.data.title, this.state.data.data.description, this.state.data.data.aid, this.state.data.data.file, 3, this.state.data.data.deadline, formattedDateTime);
			this.getTicket(this.state.data.data.id);
		}
	};
	openTicket = () => {
		const shouldClose = window.confirm('Möchten Sie das Ticket wieder als offen markieren?');
		if (shouldClose) {
			updateTicket(this.state.data.data.id, this.state.data.data.title, this.state.data.data.description, this.state.data.data.aid, this.state.data.data.file, 2, this.state.data.data.deadline, null);
			this.getTicket(this.state.data.data.id);
		}
	};

	toggleShowComments = () => {
		if (this.state.unread > 0) {

			var readBy = this.state.readby;
			Object.keys(readBy).forEach((key) => {
				var id = readBy[key].id;
				var readByEntry = readBy[key].readby;
				var readByEntry = JSON.parse(readByEntry);
				var userId = this.state.userId.toString();
				readByEntry.push(userId);
				readByEntry = JSON.stringify(readByEntry);
				updateComment(id, readByEntry);
			});

			this.setState({unread: 0});

		}
		
		this.setState({ showComments: true });
	}
	toggleShowCommentsBack = () => {
		this.setState({ showComments: false });
	}

	toggleEdit = () => {
		this.setState({ edit: true });
		this.getAllProjects();
	}
	toggleEditBack = () => {
		this.setState({ edit: false });
	}
	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		this.setState({
			ticketData: {
				...this.state.ticketData,
				[name]: val
			}
		});
	}

	updateMessage(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		this.setState({ message: val });
	}
	
	saveTicket = () => {
		var ticketData = this.state.ticketData;
		if (ticketData.title && ticketData.description && ticketData.aid) {
			this.setState({ isReady: false });
			this.editTicketData(ticketData);
		} else {
			alert('Bitte alle Felder ausfüllen!');
		}
	}

	async editTicketData(ticketData) {
		const response = await updateTicket(ticketData.id, ticketData.title, ticketData.description, ticketData.aid, ticketData.file, ticketData.state, ticketData.deadline, null)
		if (response.data.success) {
			this.setState({ edit: false });
			this.getTicket(ticketData.id);
			this.setState({ isReady: true });
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}
	async getAllProjects() {
		const response = await getAllProjects();
		this.setState({ projects: response.data.data });
	}

	saveComment = () => {
		if (this.state.message) {
			var ticket = this.state.data.data.id;
			var user = this.state.userId;
			var message = this.state.message;
			this.setState({ isReady: false });
			this.saveCommentData(ticket, user, message);
		}
	}
	async saveCommentData(ticket, user, comment) {
		const response = await saveCommentData(ticket, user, comment);
		if (response.data.success) {
			this.setState({ edit: false });
			this.getTicket(ticket);
			this.setState({ isReady: true });
			this.setState({ showComments: true });
			this.setState({ message: '' });
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	deleteTicketConfirm = (id) => {
		const shouldClose = window.confirm('Möchten Sie das Ticket löschen?');
		if (shouldClose) {
			this.deleteTicket(id);
			this.setState({ isReady: false });
		}
	};

	async deleteTicket(id) {
		const response = await deleteTicket(id);
		if (response.data.success) {
			window.history.go(-1)
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	componentDidMount() {
		let { id } = this.props.params;
		this.getTicket(id);
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
		this.setState({ myUserName: this.props.user.user_firstname + ' ' + this.props.user.user_lastname})
		this.setState({ location: this.props.location.state });

		const urlParams = new URLSearchParams(window.location.search);
		const commentParam = urlParams.get('comment');

		if (commentParam === 'true') {
			this.toggleShowComments()
		}

		window.scrollTo(0, 0);
	}

	showPlan(path) {
		this.setState({showPlan: true});
		this.initializeWebViewer(path, this.state.data.data);
	}

	hidePlan(path) {
		this.setState({showPlan: false});
	}

	hideAddPlan() {
		this.setState({ addPlan: false })
	}

	async addPlan(evt) {

		this.setState({ addPlan: true })
		
		var project = this.state.data.data.project;

		if (evt.target.name == 'houses') {
			const house = evt.target.value;

			if (house) {
				this.setState({
					filters: {
						...this.state.filters,
						'project': project,
						'house': house,
					}
				}, () => {
					this.getFiles();
				});			
			}			
		}

		if (evt.target.name == 'plans') {
			var chosenPlan = this.state.plans[evt.target.value - 1];

			var planId = null;
			if (Array.isArray(chosenPlan.files)) {
				if (chosenPlan.files.length > 0) {
					planId = chosenPlan.files[0].id;
				} else {
					planId = null;
					alert('Für die Auswahl existiert kein Plan!');
				}
			} else {
				planId = chosenPlan.files.id;
			}

			if (planId) {
				this.setState({ planId: planId })
			}
		}


	}

	checkCheck(index) {
		this.setState((prevState) => {
			const updatedChecklist = prevState.checklist.map((check, i) => {
				if (i === index) {
					return { ...check, done: !check.done };
				}
				return check;
			});

			return { checklist: updatedChecklist };
		}, () => {
			this.updateTodos();
		});
	}

	removeCheck(index) {
		var shouldRemove = window.confirm('Todo löschen?');

		if (shouldRemove) {
			this.setState(
				(prevState) => ({
					checklist: prevState.checklist.filter((_, i) => i !== index),
				}),
				() => {
					this.updateTodos();
				}
			);
		}
	}

	addCheck() {
		const newItem = prompt('Todo eingeben');

		if (newItem !== null && newItem !== '') {
			this.setState(
				(prevState) => ({
					checklist: [...prevState.checklist, { item: newItem, done: false }],
				}),
				() => {
					this.updateTodos();
				}
			);
		}
	}

	async updateTodos() {
		this.setState({ isReady: false });

		var todos = JSON.stringify(this.state.checklist)
		
		const response = await updateTodos(this.props.params.id, this.state.checklist);
		if (response.data.success) {
			this.setState({ isReady: true });
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}



	async getFiles() {
		const response = await getPlansApi(this.state.filters);

		var data = response.data.data;

		this.setState({ plans: data });

	}

	addPlanInitial() {
		var id = this.props.params.id;
		var plan = this.state.planId;
		var X = 100;
		var Y = 100;

		this.addPlanPositionInitial(id, plan, X + ' ' + Y);
	}

	savePosition = (annotationsObj) => {

		var id = this.props.params.id;

		var plan = this.state.plan;

		var X =  Math.floor(annotationsObj[0]['cC']);
		var Y =  Math.floor(annotationsObj[0]['dC']);
		
		this.addPlanPosition(id, plan, X + ' ' + Y);

	}

	async addPlanPosition(id, plan, plancoords) {
		const response = await updatePlanPosition(id, plan, plancoords);
	}

	async addPlanPositionInitial(id, plan, plancoords) {
		const response = await updatePlanPosition(id, plan, plancoords);
		if (response) {
			this.getTicket(id, true);			
		}
	}

	initializeWebViewer(path, data) {

			WebViewer(
				{
					path: '/webviewer',
					initialDoc: path,
					fullAPI: true,
				},
				this.viewer.current,
			).then((instance) => {

				instance.UI.disableElements(['underlineToolGroupButton']);

				instance.UI.disableElements(['highlightToolGroupButton']);
				instance.UI.disableElements(['shapeToolGroupButton']);
				instance.UI.disableElements(['freeTextToolGroupButton']);
				instance.UI.disableElements(['freeHandHighlightToolGroupButton']);
				instance.UI.disableElements(['freeHandToolGroupButton']);
				instance.UI.disableElements(['squigglyToolGroupButton']);
				instance.UI.disableElements(['strikeoutToolGroupButton']);
				instance.UI.disableElements(['viewControlsButton']);
				instance.UI.disableElements(['toolsOverlay']);
				instance.UI.disableElements(['undoButton']);
				instance.UI.disableElements(['redoButton']);
				instance.UI.disableElements(['eraserToolButton']);
				instance.UI.disableElements(['leftPanelButton']);
				instance.UI.disableElements(['menuButton']);
				instance.UI.disableElements(['ribbonsDropdown']);
				instance.UI.disableElements(['ribbons']);
				instance.UI.disableElements(['annotationPopup']);
				instance.UI.disableElements(['toggleNotesButton']);
				instance.UI.disableElements(['stickyToolGroupButton']);
				instance.UI.disableReplyForAnnotations((annot) => true);
				
				const { docViewer, documentViewer, annotationManager, Annotations, UI, Actions, Tools } = instance.Core;

				const panTool = Tools.ToolNames.PAN;
				instance.UI.setToolMode(panTool);


				var coords = data.plancoords.split(' ');
				var X = parseFloat(coords[0]);
				var Y = parseFloat(coords[1]);

				documentViewer.addEventListener('documentLoaded', () => {
				    const annot = new Annotations.StickyAnnotation({
				        PageNumber: 1,
				        X: X,
				        Y: Y,
				        Icon: Annotations.StickyAnnotation.IconNames.CROSS,
				    });
				    annot.setContents(this.state.data.title);
				    annot.Author = this.state.myUserName;

				    var annotHeight = annot.getHeight();

				    const lineX = new Annotations.LineAnnotation();
				    lineX.setStartPoint(X + annotHeight, 0);
				    lineX.setEndPoint(X + annotHeight, documentViewer.getPageHeight(1));
				    lineX.StrokeColor = new Annotations.Color(0, 124, 197);
				    lineX.StrokeThickness = 2;
				    
				    const lineY = new Annotations.LineAnnotation();
				    lineY.setStartPoint(0, Y + annotHeight);
				    lineY.setEndPoint(documentViewer.getPageWidth(1), Y + annotHeight);
				    lineY.StrokeColor = new Annotations.Color(0, 124, 197);
				    lineY.StrokeThickness = 2;

				    annotationManager.addAnnotation(lineX);
				    annotationManager.addAnnotation(lineY);
				    annotationManager.addAnnotation(annot);

				    annotationManager.redrawAnnotation(lineX);
				    annotationManager.redrawAnnotation(lineY);
				    annotationManager.redrawAnnotation(annot);

					documentViewer.addEventListener('zoomUpdated', (event) => {
						const annotationsObj = annotationManager.getAnnotationsList().filter(annot => annot instanceof Annotations.StickyAnnotation);
						annotHeight = (annot.getHeight() / event) / 2;
						updateLines(annotationsObj[0].cC + annotHeight, annotationsObj[0].dC + annotHeight);
					});

				    const updateLines = (x, y) => {
				        lineX.setStartPoint(x, 0);
				        lineX.setEndPoint(x, documentViewer.getPageHeight(1));
				        lineY.setStartPoint(0, y);
				        lineY.setEndPoint(documentViewer.getPageWidth(1), y);

				        annotationManager.redrawAnnotation(lineX);
				        annotationManager.redrawAnnotation(lineY);
				    };

					annotationManager.addEventListener('drag', (annotations, action) => {
						console.log(action)
					});

					annotationManager.addEventListener('annotationChanged', (annotations, action) => {
						if (action == 'modify' || action == 'delete') {
							const annotationsObj = annotationManager.getAnnotationsList().filter(annot => annot instanceof Annotations.StickyAnnotation);
							
							// get zoom level here

							updateLines(annotationsObj[0].cC + annotHeight, annotationsObj[0].dC + annotHeight)

							this.savePosition(annotationsObj);
						}
					});

				});


				annotationManager.setCurrentUser(this.state.myUserName);



				this.setState({ isReady: true });

			});
	}

	render() {

		const { data, users, comments } = this.state.data;

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.showComments) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.toggleShowCommentsBack}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Kommentare</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container comments-wrapper">
						<div className="row comments">
							{Object.keys(comments).map(index => (
								<div key={index} className={`comment col-12 ${comments[index].user == this.state.userId ? 'me' : ''}`}>
									<div className="text">
										<div className="name">
											{ comments[index].username }
										</div>
										{ comments[index].comment }
									</div>
									<div className="time">
										{ comments[index].created }
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="container message-outer">
						<div className="row">
							<div className="form-group col-10 pr-0">
								<textarea
									type="text"
									name="message"
									value={ this.state.message }
									className="form-control message"
									onChange={evt => this.updateMessage(evt)}
								/>
							</div>
							<div className="col-2 pl-0">
								<a
									className="btn btn-sm btn-success btn-message"
									onClick={ e => this.saveComment(e) }
								>s</a>
							</div>
						</div>
					</div>
				</>	
			);	
		}

		if (this.state.edit == true) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.toggleEditBack}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Ticket bearbeiten</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Benutzer</label>
									<select
										className="form-select"
										onChange={evt => this.updateInputValue(evt)}
										name="aid"
										value={this.state.ticketData.aid}
									>
										<option value="">Benutzer wählen</option>
										{users.map((option) => (
											<option key={option.id} value={option.id}>
												{ option.name}
											</option>
										))}
									</select>
								</div>
								<div className="form-group">
									<label>Titel*</label>
									<input
										type="text"
										name="title"
										value={this.state.ticketData.title}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Beschreibung*</label>
									<textarea
										type="text"
										name="description"
										value={this.state.ticketData.description}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Deadline</label>
									<input
										type="date"
										name="deadline"
										value={this.state.ticketData.deadline}
										className="form-control"
										onChange={evt => this.updateInputValue(evt)}
									/>
								</div>	
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.saveTicket(e) }
								>Ticket speichern</button>
							</div>
						</div>
					</div>
				</>
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-1">
								<GoBack />
							</div>
							<div className="col-10 text-center">
								<h1>Ticket</h1>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<h2>{ data.projectname }: { data.title }</h2>
				</div>
				<div className="container ticket-infos mt-2">
					<div className="inner">
						<div className="row">
							<div className="col-4">
								<strong>Status:</strong>
							</div>
							<div className="col-8">
								{ data.statename }
								{ data.state == 3 && data.done &&
									<> am {data.done}</>
								}
							</div>						
						</div>
						{ data.gewerk &&
							<div className="row">
								<div className="col-4">
									<strong>Gewerk:</strong>
								</div>
								<div className="col-8">
									{ data.gewerk }
								</div>						
							</div>
						}
						{ data.company &&
							<div className="row">
								<div className="col-4">
									<strong>Firma:</strong>
								</div>
								<div className="col-8">
									{ data.company }
								</div>						
							</div>
						}
						<div className="row">
							<div className="col-4">
								<strong>Erstellt von:</strong>
							</div>
							<div className="col-8">
								{ data.cname }
							</div>						
						</div>
						<div className="row">
							<div className="col-4">
								<strong>Erstellt für:</strong>
							</div>
							<div className="col-8">
								{ data.aname }
								{ data.aname2 !== ' ' && 
									<>, { data.aname2 }</>
								}
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<strong>Erstellt am:</strong>
							</div>
							<div className="col-8">
								{ data.created }
							</div>						
						</div>
						{ data.done &&
							<div className="row">
								<div className="col-4">
									<strong>Erledigt am:</strong>
								</div>
								<div className="col-8">
									{ data.done }
								</div>						
							</div>
						}
						{ data.doneby &&
							<div className="row">
								<div className="col-4">
									<strong>Erledigt von:</strong>
								</div>
								<div className="col-8">
									{ data.doneby }
								</div>						
							</div>
						}
						{ data.deadline &&
							<div className="row">
								<div className="col-4">
									<strong>Deadline:</strong>
								</div>
								<div className="col-8">
									{ data.deadline }
								</div>						
							</div>
						}
						{ data.projectname &&
							<div className="row">
								<div className="col-4">
									<strong>Projekt:</strong>
								</div>
								<div className="col-8">
									{ data.projectname }
								</div>						
							</div>
						}
						{ data.housename &&
							<div className="row">
								<div className="col-4">
									<strong>Haus:</strong>
								</div>
								<div className="col-8">
									{ data.housename }
								</div>						
							</div>
						}
					</div>
				</div>
				{ data.category &&
					<div className="container mt-2">
						<div className="row mt-3">
							<div className="col-12">
								<strong>Kategorie:</strong>
							</div>
							<div className="col-12">
								{ data.category }
							</div>						
						</div>
					</div>
				}
				<div className="container mt-2">
					<div className="row mt-3">
						<div className="col-12">
							<strong>Beschreibung:</strong>
						</div>
						<div className="col-12">
							{ data.description }
						</div>						
					</div>
				</div>

				<div className="container mt-2">
					<div className="row mt-3">
						<div className="col-12 add-todo-outer">
							<strong>Checklist:</strong>
							<span className="add-todo" onClick={() => this.addCheck()}></span>
						</div>
						{ this.state.checklist.length > 0 &&
							<div className="col-12">
								<ul class="list-group checklist">
									{this.state.checklist.map((check, index) => (
										<>
										<li class="list-group-item">
											<span onClick={() => this.checkCheck(index)} className={` ${check.done ? 'text-decoration-line-through checked' : ''}`}>
												{check.item}
											</span>
											{ this.state.isEditor &&
												<div className="remove-todo" onClick={() => this.removeCheck(index)}></div>
											}
										</li>

										</>
									))}
								</ul>
							</div>	
						}					
					</div>
				</div>
				
				
				{ data.upload && data.upload !== '0' &&
					<div className="container mt-2">
						<div className="row mt-3">
							<div className="col-12">
								<strong>Bild/Datei:</strong>
							</div>
							<div className="col-12">
								<a className="mt-2" target="_blank" href={ 'https://backend.spl-hausbau.app/' + data.upload }>
									Datei anzeigen
								</a>
							</div>						
						</div>
					</div>
				}
				{ data.upload2 && data.upload2 !== '0' &&
					<div className="container mt-2">
						<div className="row mt-1">
							<div className="col-12">
								<a className="mt-2" target="_blank" href={ 'https://backend.spl-hausbau.app/' + data.upload2 }>
									Datei anzeigen
								</a>
							</div>						
						</div>
					</div>
				}
				{ data.upload3 && data.upload3 !== '0' &&
					<div className="container mt-2">
						<div className="row mt-1">
							<div className="col-12">
								<a className="mt-2" target="_blank" href={ 'https://backend.spl-hausbau.app/' + data.upload3 }>
									Datei anzeigen
								</a>
							</div>						
						</div>
					</div>
				}
				{ data.upload4 &&
					<div className="container mt-2">
						<div className="row mt-1">
							<div className="col-12">
								<a className="mt-2" target="_blank" href={ 'https://backend.spl-hausbau.app/' + data.upload4 }>
									Datei anzeigen
								</a>
							</div>						
						</div>
					</div>
				}
				{ data.upload5 &&
					<div className="container mt-2">
						<div className="row mt-1">
							<div className="col-12">
								<a className="mt-2" target="_blank" href={ 'https://backend.spl-hausbau.app/' + data.upload5 }>
									Datei anzeigen
								</a>
							</div>						
						</div>
					</div>
				}

				<div className="container mt-3">

					{ data.plan && data.plancoords &&
						<div className="row mb-1">
							<div className="col-12">
								<div
									className="btn btn-secondary btn-block"
									onClick={() => this.showPlan(this.state.planPath)}
								>
									Auf Plan anzeigen
								</div>
							</div>
						</div>
					}
					{ !data.plan && !data.plancoords &&
						<>
							<div className="row mb-1">
								<div className="col-12">
									<div
										className="btn btn-outline-secondary btn-block"
										onClick={() => this.addPlan()}
									>
										Position auf Plan setzen
									</div>
								</div>
							</div>
							{ this.state.addPlan &&
								<div className="plan-overlay">
									<div className="plan-overlay-inner container">
										<div
											className="closinger"
											onClick={() => this.hideAddPlan()}
										></div>
										<div className="form-group">
											<select
												className="form-select"
												onChange={evt => this.addPlan(evt)}
												name="houses"
												value={this.state.house}
											>
												<option value="">Haus wählen</option>
												{this.state.houses.map((option) => (
													<option key={option.id} value={option.id}>
														{ option.name}
													</option>
												))}
											</select>
										</div>
										{ this.state.plans &&
											<div className="form-group">
												<select
													className="form-select"
													onChange={evt => this.addPlan(evt)}
													name="plans"
													value={this.state.plan}
												>
													<option value="">Plan wählen</option>
													{this.state.plans.map((option) => (
														<option key={option.id} value={option.id}>
															{ option.name}
														</option>
													))}
												</select>
											</div>
										}	
										{ this.state.planId &&
											<div className="row mb-1">
												<div className="col-12">
													<a
														className="btn btn-success btn-block"
														onClick={() => this.addPlanInitial()}
													>
														Position auf Plan setzen
													</a>
												</div>
											</div>
										}
									</div>							
								</div>
							}

						</>
					}
					<div className="FilesPdf overlay" style={{ display: this.state.showPlan ? 'block' : 'none' }}>
						<div
							className="closinger"
							onClick={() => this.hidePlan()}
						></div>
						<div className="webviewer" ref={this.viewer} style={{height: "calc(100vh - 120px)", width: "calc(100vw - 40px)", left: '20px', top: '60px', position: 'absolute'}}></div>
					</div>
					<div className="row mb-1">
						<div className="col-12">
							<a
								className="btn btn-secondary btn-block"
								onClick={() => this.toggleShowComments()}
							>
								Kommentare ({ comments.length })
								{ this.state.unread > 0 &&
									<span className="unread">{ this.state.unread }</span>
								}
							</a>
						</div>
					</div>
					{ this.state.isOpenClose && data.state !== 3 &&
						<div className="row mb-1">
							<div className="col-12">
								<a
									className="btn btn-success btn-block"
									onClick={() => this.closeTicket()}
								>Ticket abschließen</a>
							</div>
						</div>
					}
					{ this.state.isOpenClose && data.state == 3 &&
						<div className="row mb-1">
							<div className="col-12">
								<a
									className="btn btn-secondary btn-block"
									onClick={() => this.openTicket()}
								>Ticket öffnen</a>
							</div>
						</div>
					}
					{ this.state.isEditor &&
						<div className="row mb-1">
							<div className="col-12">
								<a
									className="btn btn-secondary btn-block"
									onClick={() => this.toggleEdit()}
								>Ticket bearbeiten</a>
							</div>
						</div>
					}
					{ this.state.userRole == 1 &&
						<div className="row mb-4">
							<div className="col-12">
								<a
									className="btn btn-danger btn-block"
									onClick={() => this.deleteTicketConfirm(this.props.params.id)}
								>Ticket löschen</a>
							</div>
						</div>
					}
				</div>
				
			</>
		);
	}

}

export default withParams(TicketDetails);
