import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/de';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { HeaderLogo, GoBack } from './Parts/Header.js';

import { getTimeEntries, updateCalEntry, startTimeEntry, endTimeEntry } from '../helper/restapi.js';


const DnDCalendar = withDragAndDrop(Calendar);

class Time extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			times: [],
			projects: [],
			users: [],
			userId: '',
			userRole: '',
			userFirstname: '',
			admin: false,
			user: '',
			chosenuser: null,
			add: false,
			save: false,
			addEntry: {
				location: 'Kanzlei',
				comment: '',
			},
			active: false
		};
		this.showAdd = this.showAdd.bind(this);
	}

	async getTimeEntries(user) {
		const response = await getTimeEntries(user);
		if (response.data.success == 'true') {

			this.setState({
				times: response.data.data,
				projects: response.data.projects,
				users: response.data.users
			})

			if (response.data.data.length > 0) {
				if (!response.data.data[0].tto) {
					this.setState({ active: true })
				}
			}
		}
	}

	calculateTimeDifference(startTime, endTime) {
	    // Parse the time strings into Date objects
	    const startDate = new Date(startTime);
	    const endDate = new Date(endTime);

	    // Calculate the difference in milliseconds
	    const diffInMilliseconds = endDate - startDate;

	    // Convert milliseconds to total minutes
	    const totalMinutes = Math.floor(diffInMilliseconds / 60000);

	    // Calculate hours and minutes
	    const hours = Math.floor(totalMinutes / 60);
	    const minutes = totalMinutes % 60;

	    // Format the output as hh:mm
	    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
	    return formattedTime;
	}

	updateUser(evt) {

		var userId = evt.target.value;
		this.getTimeEntries(userId);

	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;
		this.setState({
			addEntry: {
				...this.state.addEntry,
				[name]: val
			}
		});
	}

	showAdd() {
		this.setState({add: true})
	}

	hideAdd() {
		this.setState({add: false, save: false})
	}

	async timeStart() {

		var userId = this.props.user.user_id;
		var location = this.state.addEntry.location;

		const response = await startTimeEntry(userId, location);

		console.log(response)

		if (response.data.success) {
			this.setState({add: false})
			this.getTimeEntries(this.props.user.user_id)
		}
	}

	showSave() {
		this.setState({save: true})
	}
	
	async timeEnd() {

		var comment = this.state.addEntry.comment;
		var id = this.state.times[0].id;

		const response = await endTimeEntry(id, comment);

		console.log(response.data)

		if (response.data.success) {
			this.setState({save: false, active: false})
			this.getTimeEntries(this.props.user.user_id)			
		}
	}

	componentDidMount() {
		this.setState({ isReady: true });
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
		this.setState({ user: this.props.user.user_firstname + ' ' + this.props.user.user_lastname });

		this.getTimeEntries(this.props.user.user_id);

		if (this.props.user.user_id == 6 || this.props.user.user_id == 8 || this.props.user.user_id == 1  || this.props.user.user_id == 3) {
			this.setState({ admin: true })
		} else {
			
		}

		
	}

	render() {

		if (!this.state.isReady) {
			return <HeaderLogo />;
		}

		return (
			<>

				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								<GoBack />
							</div>
							<div className="col-8 text-center">
								<h1>Zeiterfassung</h1>
							</div>
							<div className="col-2 add-contact text-end">
								{ !this.state.active &&
									<div
										className="add-link"
										onClick={this.showAdd}
									>+</div>
								}
							</div>
						</div>
					</div>
				</div>

				<div className="container mt-4 time-entries">

					<h4>Zeiterfassung:</h4>

					{ this.state.admin &&
						<select
							className="form-select"
							onChange={evt => this.updateUser(evt)}
							name="location"
							value={this.state.chosenuser}
						>
							<option value={this.props.user.user_id}>Meine Zeiterfassung</option>
							{this.state.users.map((option) => (
								<option key={option.id} value={option.id} full={option.name}>
									{ option.name }
								</option>
							))}
						</select>
					}

					{ this.state.add &&
						<div className="row">
							<div className="col-12">
								<div className="border p-3">
									<div className="form-group">
										<label>Ort</label>
										<select
											className="form-select"
											onChange={evt => this.updateInputValue(evt)}
											name="location"
											value={this.state.addEntry.location}
										>
											<option value="Kanzlei">Kanzlei</option>
											{this.state.projects.map((option) => (
												<option key={option.id} value={option.name}>
													{ option.name }
												</option>
											))}
										</select>

										<button
											type="submit"
											className="btn btn-primary btn-block mb-0 mt-2"
											onClick={ e => this.timeStart(e) }
										>Starten</button>
										<button
											type="submit"
											className="btn btn-danger btn-block mb-0 mt-2"
											onClick={ e => this.hideAdd(e) }
										>Abbrechen</button>
									</div>

								</div>

							</div>
						</div>
					}

					{ this.state.save && this.state.times &&
						<div className="row">
							<div className="col-12">
								<div className="border p-3">
									<h4>Zeitaufzeichnung beenden</h4>
									<div className="form-group">
										<div className="form-group">
											<label>Notiz</label>
											<textarea 
												className="form-control"
												name="comment"
												value={ this.state.addEntry.comment }
												rows="3"
												onChange={evt => this.updateInputValue(evt)}
											>
											</textarea>
										</div>
										<button
											type="submit"
											className="btn btn-primary btn-block mb-0 mt-2"
											onClick={ e => this.timeEnd(e) }
										>Speichern</button>
										<button
											type="submit"
											className="btn btn-danger btn-block mb-0 mt-2"
											onClick={ e => this.hideAdd(e) }
										>Abbrechen</button>
									</div>

								</div>

							</div>
						</div>
					}

                    <div className="row entries head d-none d-lg-block">
                    	<div className="col-12 p-4">
                    		<div className="row">
	                            <div className="entry first col-12 col-lg-2">
	                            	Zeit von
	                            </div>
	                            <div className="entry col-12 col-lg-2">
	                            	Zeit bis
	                            </div>
	                            <div className="entry col-12 col-lg-2">
	                            	Dauer
	                            </div>
	                            <div className="entry col-12 col-lg-2">
	                            	Ort
	                            </div>
	                            <div className="entry col-12 col-lg-2">
	                            	Kommentar
	                            </div>
	                            <div className="entry col-12 col-lg-2">
	                            	Aktionen
	                            </div>
                            </div>
                        </div>
                    </div>

                    {this.state.times && this.state.times.map((item) => (
                        <div className="row entries" key={item.id}>
                        	<div className="col-12 p-4">
                        		<div className={`row ${!item.tto ? 'active' : ''}`}>
		                            <div className="entry first col-12 col-lg-2">
		                            	<div className="label d-block d-lg-none">Zeit von</div>
		                            	{item.tfrom}
		                            </div>
		                            <div className="entry col-12 col-lg-2">
		                            	<div className="label d-block d-lg-none">Zeit bis</div>
		                            	{ item.tto &&
		                            		<>{item.tto}</>
		                            	}
		                            	{ !item.tto &&
		                            		<>-</>
		                            	}		                            	
		                            </div>
		                            <div className="entry col-12 col-lg-2">
		                            	<div className="label d-block d-lg-none">Dauer</div>
		                            	{ item.tto &&
		                            		<>{ this.calculateTimeDifference(item.tfrom, item.tto) }</>
		                            	}
		                            	{ !item.tto &&
		                            		<>-</>
		                            	}		                            	
		                            </div>
		                            <div className="entry col-12 col-lg-2">
		                            	<div className="label d-block d-lg-none">Ort</div>
		                            	{item.location}
		                            </div>
		                            <div className="entry col-12 col-lg-2">
		                            	<div className="label d-block d-lg-none">Kommentar</div>
		                            	{ item.comment &&
		                            		<>{item.comment}</>
		                           		}
		                            	{ !item.comment &&
		                            		<>-</>
		                           		}		                           		
		                            </div>
		                            <div className="entry col-12 col-lg-2">
		                            	<div className="label d-block d-lg-none">Aktionen</div>
		                            	{ !item.tto &&
											<button
												type="submit"
												className="btn btn-success btn-block mb-2 mt-2"
												onClick={ e => this.showSave(e) }
											>Beenden</button>
										}
		                            	{ item.tto &&
											<>-</>
										}
		                            </div>
	                            </div>
	                        </div>
                        </div>
                    ))}
				</div>
			</>
		);
	}
}

export default Time;
