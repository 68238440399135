import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from "react-router-dom";
import { MicOutline, CameraOutline, DocumentTextOutline } from 'react-ionicons'

import { getUpdates, addNewUpdate, deleteUpdate, addRead, addUpdateComment } from '../helper/restapi.js';

import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import { HeaderLoading, GoBack, Loading } from "./Parts/Header.js";
import { NotesList } from "./Parts/Notes.js";

import { ReactComponent as IconAudio } from '../images/audio.svg';
import { ReactComponent as IconPhoto } from '../images/photo.svg';
import { ReactComponent as IconText } from '../images/text.svg';


function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}

class Updates extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			userId: '',
			userRole: '',
			userFirstname: '',
			userLastname: '',
			user: null,
			project: null,
			house: null,
			title: null,
			add: false,
			data: [],
			recording: false,
			blobURL: null,
			projects: [],
			houses: [],
			users: [],
			addType: 'image',
			addUser: '',
			addProject: '',
			addHouse: '',
			addTitle: '',
			addText: '',
			addFiles: [],
			addedFiles: [],
			comment: '',
		};
	}


	async getUpdates() {
		const response = await getUpdates(this.state.user, this.state.project, this.state.house, this.state.title);

		Object.keys(response.data.data).forEach((key) => {
			var comments = response.data.data[key].comments;
			if (comments.length > 0) {
				var currentUser = this.props.user.user_id.toString();
				var unRead = 0;
				//var readByArr = [];
				Object.keys(comments).forEach((k) => {
					if (comments[k].readby) {
						var readBy = comments[k].readby;
						var readBy = JSON.parse(readBy);

						if (!readBy.includes(currentUser)) {
							unRead++;
						}
					}
				});

				response.data.data[key]['unread'] = unRead;
				
			}
			
		});

		this.setState({ data: response.data.data });
		this.setState({ users: response.data.users });
		this.setState({ projects: response.data.projects });
	}

	componentDidMount() {
		this.getUpdates();
		this.setState({ isReady: true });
		this.setState({ userId: this.props.user.user_id });
		this.setState({ addUser: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });
		this.setState({ userLastname: this.props.user.user_lastname });
	}

	updateAdd(evt) {
		const name = evt.target.name;
		const val = evt.target.value;	
		if (name == 'project') {
			this.setState({ addProject: val })

			if (val) {
				var projectData = this.state.projects.find(item => item.id == val);
				var houses = projectData.houses;
				if (houses.length > 0) {
					this.setState({ houses: houses })
				} else {
					this.setState({ houses: []})
				} 				
			}

		}	

		if (name == 'house') {
			this.setState({ addHouse: val })
		}

		if (name == 'title') {
			this.setState({ addTitle: val })
		}

		if (name == 'text') {
			this.setState({ addText: val })
		}

		if (name == 'upload') {
			var fileNameArr = [];
			for (let i = 0; i < evt.target.files.length; i++) {
				const file = evt.target.files[i];
				fileNameArr.push(file.name)
			}
			this.setState({ addFiles: evt.target.files, addedFiles: fileNameArr })
		}
	}

	saveUpdate = () => {

		var project = this.state.addProject;
		var house = this.state.addHouse;
		var title = this.state.addTitle;
		var text = this.state.addText;
		var files = this.state.addFiles;

		if (project && title) {
			this.setState({ isReady: false });
			this.addUpdateData(project, house, title, text, files);
		} else {
			alert('Bitte wählen Sie ein Projekt und geben Sie einen Titel ein!')
		}
	}

	async addUpdateData(project, house, title, text, files) {

		const response = await addNewUpdate(project, house, title, text, files);

		if (response.data.success) {
			alert('Der Eintrag wurde erfolgreich erstellt!');
			console.log(response.data)
			this.setState({
				add: false,
				addProject: '',
				addHouse: '',
				addTitle: '',
				addText: '',
				addFiles: false,
				project: false,
				house: false,
				user: false,
				title: false,
				currentComments: '',
			})
			this.getUpdates();
			this.setState({ isReady: true });
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	updateInputValue(evt) {
		const name = evt.target.name;
		const val = evt.target.value;

		if (name == 'project') {
			this.setState({ project: val })

			if (val) {
			 var projectData = this.state.projects.find(item => item.id == val);
			 var houses = projectData.houses;
			 if (houses.length > 0) {
			 	this.setState({ houses: houses })
			 } else {
			 	this.setState({ houses: []})
			 } 				
			}
		}

		if (name == 'house') {
			this.setState({ house: val })
		}

		if (name == 'user') {
			this.setState({ user: val })
		}

	}

	deleteEntry = (id) => {
		const shouldClose = window.confirm('Möchten Sie den Eintrag löschen?');
		if (shouldClose) {
			this.deleteUpdate(id);
			this.setState({ isReady: false });
		}
	};

	async deleteUpdate(id) {
		const response = await deleteUpdate(id);
		if (response.data.success) {
			this.getUpdates();
			this.setState({ isReady: true });
		} else {
			alert('Es ist ein Fehler aufgetreten!');
			this.setState({ isReady: true });
		}
	}

	showComments = (id) => {
		if (this.state.currentComments == id) {
			this.setState({ currentComments: '' });
		} else {
			this.setState({ currentComments: id });
			this.addRead(id);			
		}

	}

	async addRead(id) {
		var data = this.state.data;
		Object.keys(data).forEach((key) => {
			if (data[key].id == id) {
				data[key].unread = 0;
			}
		})
		this.setState({ data: data });
		const response = await addRead(id);

	}

	updateMessage(evt) {
		const name = evt.target.name;
		const val = evt.target.value;
		this.setState({ comment: val });
	}

	saveComment = () => {
		if (this.state.comment) {
			this.addComment(this.state.currentComments, this.state.comment);
		}
	}

	async addComment(update, comment) {
		const response = await addUpdateComment(update, comment);
		if (response.status == 200) {
			var data = this.state.data;

			const now = new Date();

			const year = now.getFullYear();
			const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-based
			const day = String(now.getDate()).padStart(2, '0');

			const hours = String(now.getHours()).padStart(2, '0');
			const minutes = String(now.getMinutes()).padStart(2, '0');
			const seconds = String(now.getSeconds()).padStart(2, '0');

			Object.keys(data).forEach((key) => {
				if (data[key].id == update) {
					data[key].comments.push({
						id: 9,
						update: update,
						user: this.state.userId,
						username: this.state.userFirstname + ' ' + this.state.userLastname,
						comment: comment,
						created: year + '.' + month + '.' + day + ' ' + hours + ':' + minutes + ':' + seconds,
					})
				}
			})
			
			this.setState({ data: data });
			this.setState({ comment: ''});
		}
	}

	addUpdate = () => {
		this.setState({ add: true });
	}

	addUpdateBack = () => {
		this.setState({ add: false });
	}

	componentDidUpdate() {
		this.getUpdates();
	}

	render() {

		const { data, projects, houses, users } = this.state;

		if (!this.state.isReady) {
			return (
				<HeaderLoading />
			);
		}

		if (this.state.add == true) {
			return (
				<>
					<div id="header">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-2">
									<img
										className="back"
										src="/icon/back.svg"
										onClick={this.addUpdateBack}
									/>
								</div>
								<div className="col-8 text-center">
									<h1>Eintrag hinzufügen</h1>
								</div>
								<div className="col-2 add-contact text-end">
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-4 mb-5">
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Projekt</label>
									<select
										className="form-select"
										onChange={evt => this.updateAdd(evt)}
										name="project"
										value={this.state.addProject}
									>
										<option value="">Projekt wählen</option>
										{this.state.projects.map((option) => (
											<option key={option.id} value={option.id}>
												{ option.name}
											</option>
										))}
									</select>
								</div>
								{ houses.length > 0 &&
									<div className="form-group">
										<label>Haus</label>
										<select
											className="form-select"
											onChange={evt => this.updateAdd(evt)}
											name="house"
											value={this.state.addHouse}
										>
											<option value="">Haus wählen</option>
											{this.state.houses.map((option) => (
												<option key={option.id} value={option.id}>
													{ option.name}
												</option>
											))}
										</select>
									</div>
								}
								<div className="form-group">
									<label>Titel*</label>
									<input
										type="text"
										name="title"
										value={this.state.addTitle}
										className="form-control"
										onChange={evt => this.updateAdd(evt)}
									/>
								</div>
								<div className="form-group">
									<label>Text</label>
									<textarea
										type="text"
										name="text"
										value={this.state.addText}
										className="form-control"
										onChange={evt => this.updateAdd(evt)}
									/>
								</div>	
								<div>
									<div className="form-group">
										<label>Fotos</label>
									</div>
									<div>
										{ this.state.addedFiles.length < 1 &&
											<div className="camera-outer">
												<div className="camera-outer-text">
													Foto knipsen oder hochladen
												</div>
												<input
													id="imageUpload"
													name="upload"
													type="file"
													multiple="1"
													className="mb-4 image-upload"
													onChange={evt => this.updateAdd(evt)}
												/>

											</div>
										}
										{ this.state.addedFiles.length > 0 &&
											<ul>
											{this.state.addedFiles.map((item, index) => (
												<li key={index}>{item}</li>
											))}
											</ul>
										}
									</div>
								</div>
								<button
									type="submit"
									className="btn btn-primary btn-block mb-5"
									onClick={ e => this.saveUpdate(e) }
								>Eintrag erstellen</button>
							</div>
						</div>
					</div>
				</>
			);
		}

		return (
			<>
				<div id="header">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-2">
								<GoBack />
							</div>
							<div className="col-8 text-center">
								<h1>Baustellenupdates</h1>
							</div>
							<div className="col-2 add-contact text-end">
								<div
									className="add-link"
									onClick={this.addUpdate}
								>+</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container notes">
					<div className="row">
						<div className="col-12">
							<h2 className="pb-0">Baustellenupdate</h2>
						</div>
					</div>


					<button
						type="submit"
						className="btn btn-primary btn-block mb-4 mt-3"
						onClick={this.addUpdate}
					>Neuen Eintrag erstellen</button>


					<div className="form-group">
						<label>Projekt</label>
						<select
							className="form-select"
							onChange={evt => this.updateInputValue(evt)}
							name="project"
							value={this.state.project}
						>
							<option value="">Alle Projekte</option>
							{this.state.projects.map((option) => (
								<option key={option.id} value={option.id}>
									{ option.name}
								</option>
							))}
						</select>
					</div>

					{ houses.length > 0 &&
						<div className="form-group">
							<label>Haus</label>
							<select
								className="form-select"
								onChange={evt => this.updateInputValue(evt)}
								name="house"
								value={this.state.house}
							>
								<option value="">Alle Häuser</option>
								{this.state.houses.map((option) => (
									<option key={option.id} value={option.id}>
										{ option.name}
									</option>
								))}
							</select>
						</div>
					}

					<div className="form-group">
						<label>Benutzer</label>
						<select
							className="form-select"
							onChange={evt => this.updateInputValue(evt)}
							name="user"
							value={this.state.user}
						>
							<option value="">Alle Benutzer</option>
							{this.state.users.map((option) => (
								<option key={option.id} value={option.id}>
									{ option.firstname } { option.lastname }
								</option>
							))}
						</select>
					</div>

					{ data.length < 1 &&
						<p className="mt-2">Keine Updates gefunden</p>
					}

					{ data.length > 0 &&
						<>
							<div className="updates">
								{Object.keys(data).map(key => (
									<div key={key} className="update">
										<div className="inside">
											<div className="title">{ data[key].title }</div>
											
											{ data[key].text &&
												<div>{ data[key].text }</div>
											}	

											{ data[key].images.length > 0 &&
												<>
													<div className={ 'images images-' + data[key].images.length }>
														{Object.keys(data[key].images).map(imgkey => (
															<div key={imgkey} className="item">
																<a
																	href={ 'https://backend.spl-hausbau.app/updatefiles/' + data[key].files + '/' + data[key].images[imgkey].replace('_thumb', '') }
																	target="_blank"
																>
																<img src={ 'https://backend.spl-hausbau.app/updatefiles/' + data[key].files + '/' + data[key].images[imgkey] } />
																</a>
															</div>
														))}
													</div>
													<div className="clear"></div>
												</>
											}	

											<div className="meta-inner">
												Projekt:
												{ data[key].projectname &&
													<> { data[key].projectname }</>
												}
												{ data[key].housename &&
													<> | { data[key].housename }</>
												}	
												<div
													className="commentcount"
													onClick={() => this.showComments(data[key].id)}
												>
													{ data[key].commentcount > 0 &&
														<>Kommentare ({data[key].commentcount})</>
													}
													{ data[key].commentcount < 1 &&
														<>Kommentar erstellen</>
													}													
													{ data[key].unread > 0 &&
														<div className="nubsi d-inline">{data[key].unread}</div>
													}
												</div>

												{ this.state.currentComments == data[key].id &&
													<>
														<div className="comments">
															{Object.keys(data[key].comments).map(commentkey => (
																<>
																	<div className="comment">
																		<div className="inside">
																			<div>{ data[key].comments[commentkey].comment }</div>
																		</div>
																		<div className="comment-meta">{ data[key].comments[commentkey].created }  | { data[key].comments[commentkey].username }</div>
																	</div>
																</>
															))}
														<hr />
														<div className="row">
															<div className="form-group col-10 pr-0">
																<textarea
																	type="text"
																	name="message"
																	value={ this.state.comment }
																	className="form-control message"
																	onChange={evt => this.updateMessage(evt)}
																/>
															</div>
															<div className="col-2 pl-0">
																<a
																	className="btn btn-sm btn-success btn-message"
																	onClick={ e => this.saveComment(e) }
																>s</a>
															</div>
														</div>
														</div>
													</>
												}
											</div>								
										</div>
										<div className="meta">
											{ data[key].username &&
												<>{ data[key].username }, </>
											}
											{ data[key].time &&
												<>{ data[key].time }</>
											}
											{ (this.state.userId === data[key].user || this.state.userRole === 1) && (
												<> | <div 
														className="d-inline delete"
														onClick={() => this.deleteEntry(data[key].id)}
													>
														Eintrag löschen
													</div>
												</>
											)}
										</div>
									</div>
								))}
							</div>
						</>
					}					
				</div>
			</>
		);
	}

}

export default withParams(Updates);
