import React from 'react';
import ReactDOM from 'react-dom';

import { HeaderLogo } from "./Parts/Header.js";
import { Tiles } from "./Parts/Tiles.js";
import { requestForToken } from '../firebase/firebase';
import { getTickets, getFavourites, setFavourites  } from '../helper/restapi.js';


class Home extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			data: [],
			userId: '',
			userRole: '',
			userFirstname: '',
			openTickets: 0,
			remove: [],
			settings: false,
		};
		this.showEdit = this.showEdit.bind(this);
		this.saveEdit = this.saveEdit.bind(this);
	}

	async getTickets(title, cid, aid, state, project, house) {
		const response = await getTickets(title, cid, aid, state, project, house);
		const openTickets = response.data.data[1].length + response.data.data[2].length;
		this.setState({ openTickets: openTickets });
	}

	async getFavourites() {
	    const response = await getFavourites();

	    if (response.data.success && response.data.data.favourites) {
	        let favouritesArray;

	        if (typeof response.data.data.favourites === 'string') {
	            const validJsonString = response.data.data.favourites.replace(/'/g, '"');
	            this.setState({remove: JSON.parse(validJsonString)})
	        } else if (Array.isArray(response.data.data.favourites)) {
	            favouritesArray = response.data.data.favourites;
	        }


	    }
	    this.setState({ isReady: true });
	}

	showEdit() {
		this.setState({ settings: true })
	}

	async saveEdit() {
		var remove = this.state.remove;
		var remove = JSON.stringify(remove);

		const response = await setFavourites(remove);
		if (response.data.success == 'true') {
			this.setState({ settings: false })
		}

	}

    async handleCheckboxChange(title, isChecked) {
        if (!isChecked) {
            this.setState(prevState => ({
                remove: [...prevState.remove, title]
            }));
        } else {
            this.setState(prevState => ({
                remove: prevState.remove.filter(item => item !== title)
            }));
        }
    };

	componentDidMount() {
		//this.setState({ isReady: true });
		this.setState({ userId: this.props.user.user_id });
		this.setState({ userRole: this.props.user.user_role });
		this.setState({ userFirstname: this.props.user.user_firstname });

		requestForToken(this.props.user.user_id);

		this.getFavourites();

		const tiles = [
			{
				'order': 1,
				'title': 'Projekte',
				'icon': 'projects',
				'route': 'projects',
			},
			{
				'order': 2,
				'title': 'Aufgaben',
				'icon': 'docs',
				'route': 'tickets',
			},
			{
				'order': 3,
				'title': 'Kontakte',
				'icon': 'contacts',
				'route': 'contacts',
			},
			{
				'order': 4,
				'title': 'Private Notizen',
				'icon': 'notes',
				'route': 'notes',
			},
			{
				'order': 5,
				'title': 'Baustellen-Update',
				'icon': 'notes',
				'route': 'updates',
			},
			{
				'order': 7,
				'title': 'Kalender',
				'icon': 'cal',
				'route': 'calendar',
			},
			{
				'order': 9,
				'title': 'Zeiterfassung',
				'icon': 'cal',
				'route': 'time',
			},
		];

		this.getTickets(null, null, this.props.user.user_id, null, null, null);

		if (this.props.user.user_role == 1 || this.props.user.user_role == 2) {
			tiles.push({
				'order': 8,
				'title': 'Besprechungs- protokolle',
				'icon': 'docs',
				'route': 'files',				
			})
		}

		if (this.props.user.user_role == 1) {
			tiles.push({
				'order': 6,
				'title': 'Schlüssel-Management',
				'icon': 'key',
				'route': 'keys',				
			})
		}

		this.setState({ data: tiles});
	}

render() {
    const { data, remove, settings, userFirstname, openTickets, isReady } = this.state;

    // Filtered tiles (those not in the remove list)
    const filteredTiles = data.filter(tile => !remove.includes(tile.title));

    if (!isReady) {
        return <HeaderLogo />;
    }

    return (
        <>
            <HeaderLogo />
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-6">
                        <h2 className="text-left">Hallo {userFirstname}</h2>
                    </div>
                    <div className="col-6 text-end">
                        <div className="btn-modify" onClick={this.showEdit}>
                            Personalisieren
                        </div>
                    </div>
                </div>
            </div>
            <div className="container home">
                <Tiles data={filteredTiles} tickets={openTickets} />
            </div>

            {settings && (
                <div className="favourites-setting">
                    <div className="inner">
                    	<h6>Homescreen personalisieren</h6>
                    	<hr />
                        {data.map((item) => (
                            <div key={item.order} className="checky">
                                <input
                                    type="checkbox"
                                    id={`checkbox-${item.order}`}
                                    checked={!remove.includes(item.title)} // Check if it's not in 'remove'
                                    onChange={(e) => this.handleCheckboxChange(item.title, e.target.checked)}
                                />
                                <label htmlFor={`checkbox-${item.order}`}>{item.title}</label>
                            </div>
                        ))}
                        <div className="btn btn-block btn-primary" onClick={this.saveEdit}>Speichern</div>
                    </div>
                </div>
            )}
        </>
    );
}


}

export default Home;